<template>
  <div class="app-container">
    <el-row>
      <el-col :span="6">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-plus"
          @click="addProgram"
          >{{ $t("program.addProgram") }}</el-button
        >
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-view"
          @click="viewDimension"
          >{{ $t("program.viewDimension") }}</el-button
        >
      </el-col>
      <el-col :span="6" :offset="12">
        <div class="filter-container" align="right">
          <tenant-select
            style="width: 200px"
            size="small"
            class="filter-item"
            @change="getProgramPage"
            v-model="program.listQuery.tenantId"
            model="2"
          ></tenant-select>
          <el-button
            class="filter-item"
            type="primary"
            size="small"
            icon="el-icon-search"
            :loading="loading"
            @click="getProgramPage"
            >{{ $t("commons.search") }}</el-button
          >
        </div>
      </el-col>
    </el-row>
    <el-table
      v-loading="loading"
      :data="programList"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      stripe
    >
      <el-table-column
        type="index"
        align="center"
        :label="$t('commons.index')"
        width="95"
      >
      </el-table-column>
      <el-table-column prop="tenant" :label="$t('commons.tenant')">
        <template slot-scope="scope">
          <span>{{ scope.row.tenantName }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="programName" :label="$t('program.name')">
        <template slot-scope="scope">
          <template v-if="scope.row.edit">
            <el-input
              maxlength="33"
              :show-word-limit="true"
              v-model="scope.row.programName"
              clearable
              :placeholder="$t('commons.pleaseInput')"
            ></el-input>
          </template>
          <span v-else>{{ scope.row.programName }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" :label="$t('commons.status')">
        <template slot-scope="scope">
          <template v-if="scope.row.edit">
            <el-select v-model="scope.row.status">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
          <span v-else>{{ scope.row.statusName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="lastUpdateDate"
        :label="$t('commons.lastUpdateDate')"
      >
        <template slot-scope="scope">
          {{ scope.row.lastUpdateDate | dateFormat("YYYY-MM-DD hh:mm:ss") }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        :label="$t('commons.actions')"
        width="160"
      >
        <template slot-scope="scope">
          <template v-if="scope.row.edit">
            <div class="ruge-buttons">
              <el-button type="text" @click="updateProgram(scope.row)">{{
                $t("commons.save")
              }}</el-button>
              <el-button type="text" @click="cancelEditProgram(scope.row)">{{
                $t("commons.cancel")
              }}</el-button>
            </div>
          </template>
          <template v-else>
            <div class="ruge-buttons">
              <el-button type="text" @click="allotDimension(scope.row)">{{
                $t("program.dataDimension")
              }}</el-button>
              <el-button type="text" @click="editProgram(scope.row)">{{
                $t("commons.edit")
              }}</el-button>
              <el-button
                type="text"
                style="color: #ff0000"
                @click="deleteProgram(scope.row)"
                >{{ $t("commons.delete") }}</el-button
              >
            </div>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="program.total > 0"
      :total="program.total"
      :page.sync="program.listQuery.current"
      :limit.sync="program.listQuery.rowCount"
      @pagination="getProgramPage"
    />
    <!-- 增加数据范围 -->
    <el-dialog
      :visible.sync="editMode"
      width="30%"
      :before-close="quitAddProgram"
    >
      <template slot="title">
        <title-icon />{{ $t("program.addProgram") }}
      </template>
      <el-form
        label-position="top"
        :model="form"
        ref="programForm"
        :rules="programRule"
      >
        <el-form-item :label="$t('commons.tenant')" prop="tenantId">
          <tenant-select v-model="form.tenantId" model="2"></tenant-select>
        </el-form-item>
        <el-form-item :label="$t('program.name')" prop="programName">
          <el-input
            maxlength="33"
            :show-word-limit="true"
            v-model="form.programName"
            autocomplete="off"
            clearable
            :placeholder="$t('commons.pleaseInput')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('commons.status')" prop="status">
          <el-select
            v-model="form.status"
            :placeholder="$t('commons.selectPlease')"
            style="width: 100%"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="quitAddProgram()">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button type="primary" @click="saveProgram()">{{
          $t("commons.save")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
let manager = require("@/api/ruge/security/program");
// import DimensionChoice from "@/views/ruge/program/dimensionChoice";
// import DimensionList from "@/views/ruge/program/dimensionList";
// import TenantSelect from "@/components/TenantSelect";
import Pagination from "@/components/Pagination";

// const defineTab = {
//   programTab: {
//     tabName: "1",
//     tabTitle: "programTab",
//   },
//   choiceDimensionTab: {
//     tabName: "2",
//     tabTitle: "choiceDimensionTab",
//   },
//   viewDimensionTab: {
//     tabName: "3",
//     tabTitle: "viewDimensionTab",
//   },
// };

export default {
  name: "ProgramManager",
  components: { Pagination },
  data() {
    return {
      loading: true,
      // activeTabName: defineTab.programTab.tabName,
      // choiceDimensionTab: null,
      // viewDimensionTab: null,
      programList: null,
      editMode: false,
      options: [
        { value: 1, label: this.$t("commons.active") },
        { value: 2, label: this.$t("commons.unActive") },
      ],
      currentRow: {
        programId: null,
        programName: null,
        tenantId: null,
      },
      program: {
        total: 0,
        listQuery: {
          current: 1,
          rowCount: 10,
          tenantId: null,
        },
      },
      form: {
        programName: "",
        tenantId: null,
        status: 1,
      },
      programRule: {
        programName: [
          {
            required: true,
            trigger: ["change"],
            message: this.$t("validate.required"),
          },
        ],
        tenantId: [
          {
            required: true,
            trigger: ["change"],
            message: this.$t("validate.required"),
          },
        ],
        status: [
          {
            required: true,
            trigger: ["change"],
            message: this.$t("validate.required"),
          },
        ],
      },
    };
  },
  created() {
    this.getProgramPage();
  },
  computed: {
    programTab() {
      return defineTab.programTab;
    },
  },
  methods: {
    getProgramPage() {
      this.loading = true;
      manager
        ._sys_getProgramPage(this.program.listQuery)
        .then((response) => {
          this.programList = response.rows.map((v) => {
            this.$set(v, "statusName", this.getStatusDisplay(v.status));
            this.setOrgProgram(v);
            return v;
          });
          this.program.total = response.total;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(`查询失败，原因 => ${error}`);
        });
    },
    editProgram(row) {
      this.$set(row, "edit", true);
    },
    deleteProgram(row) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          manager
            ._sys_deleteProgram({
              tenantId: row.tenantId,
              programId: row.programId,
            })
            .then(() => {
              this.$message({
                type: "success",
                message: this.$t("message.deleteSuccess"),
              });
              if (this.program.total % this.program.listQuery.rowCount == 1) {
                this.program.listQuery.current =
                  this.program.listQuery.current - 1;
              }
              this.getProgramPage();
            })
            .catch(() => {});
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    updateProgram(row) {
      manager
        ._sys_updateProgram({
          programId: row.programId,
          programName: row.programName,
          status: row.status,
        })
        .then(() => {
          row.edit = false;
          this.setOrgProgram(row);
          this.$message({
            type: "success",
            message: this.$t("message.saveSuccess"),
          });
        })
        .catch((error) => {
          console.log(`保存失败，原因 => ${error}`);
        });
    },
    cancelEditProgram(row) {
      this.resetOrgProgram(row);
      row.edit = false;
    },
    getStatusDisplay(status) {
      let statusName;
      if (status == 1) {
        statusName = this.$t("commons.active");
      } else {
        statusName = this.$t("commons.unActive");
      }
      return statusName;
    },
    setOrgProgram(row) {
      row.orgProgramName = row.programName;
      row.orgStatus = row.status;
      row.statusName = this.getStatusDisplay(row.status);
    },
    resetOrgProgram(row) {
      row.programName = row.orgProgramName;
      row.status = row.orgStatus;
      row.statusName = this.getStatusDisplay(row.status);
    },
    addProgram() {
      this.editMode = true;
    },
    quitAddProgram() {
      this.$refs.programForm.resetFields();
      this.editMode = false;
    },
    saveProgram() {
      this.$refs.programForm.validate((valid) => {
        if (valid) {
          manager
            ._sys_createProgram(this.form)
            .then(() => {
              this.editMode = false;
              this.form.programName = null;
              this.form.status = null;
              this.$message({
                type: "success",
                message: this.$t("message.saveSuccess"),
              });
              this.getProgramPage();
            })
            .catch(() => {});
        }
      });
    },
    allotDimension(row) {
      // this.choiceDimensionTab = defineTab.choiceDimensionTab;
      // this.currentRow.programName = row.programName;
      // this.currentRow.programId = row.programId;
      // this.currentRow.tenantId = row.tenantId;
      // this.activeTabName = defineTab.choiceDimensionTab.tabName;
      this.$router.push({
        path: "/sys/permission/program/allotPermission",
        query: {
          programName: row.programName,
          programId: row.programId,
          tenantId: row.tenantId,
        },
      });
    },
    viewDimension() {
      //查看维度
      // this.viewDimensionTab = defineTab.viewDimensionTab;
      // this.activeTabName = defineTab.viewDimensionTab.tabName;
      this.$router.push({
        path: "/sys/permission/program/viewDimensionTab",
      });
    },
    // removeTab(tabName) {
    //   if (tabName == "2") {
    //     this.choiceDimensionTab = null;
    //   } else if (tabName == "3") {
    //     this.viewDimensionTab = null;
    //   }
    //   this.activeTabName = defineTab.programTab.tabName;
    // },
  },
};
</script>
