import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

////////////////===================系统管理数据范围
//获取数据权限集合
export function _sys_getProgramList(params) {
    return request({
      url: envInfo.bgApp.securityPath + '/sys/program/list/all',
      method: 'get',
      params
    })
  }

//获取数据权限分页
export function _sys_getProgramPage(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/sys/program/page/all',
    method: 'get',
    params
  })
}

//保存数据权限
export function _sys_grantProgram(params) {
    return request({
      url: envInfo.bgApp.securityPath + '/sys/program/createProgramRelation',
      method: 'post',
      data: params
    })
}

//获取当前用户数据权限集
export function _sys_getCurrentUserProgramList(params) {
    return request({
      url: envInfo.bgApp.securityPath + '/sys/program/find/program',
      method: 'get',
      params
    })
}

//创建数据权限
export function _sys_createProgram(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/sys/program/create',
    method: 'post',
    data: params
  })
}

//更改数据权限
export function _sys_updateProgram(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/sys/program/update',
    method: 'put',
    data: params
  })
}

//删除数据权限
export function _sys_deleteProgram(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/sys/program/deleteProgram',
    method: 'delete',
    data: params
  })
}

////////////////===================租户管理数据范围 ===================
//获取数据权限集合
export function _tenant_getProgramList(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/tenant/program/list/all',
    method: 'get',
    params
  })
}

//获取数据权限分页
export function _tenant_getProgramPage(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/tenant/program/page/all',
    method: 'get',
    params
  })
}

//保存数据权限
export function _tenant_grantProgram(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/tenant/program/createProgramRelation',
    method: 'post',
    data: params
  })
}

//获取当前用户数据权限集
export function _tenant_getCurrentUserProgramList(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/tenant/program/find/program',
    method: 'get',
    params
  })
}

//创建数据权限
export function _tenant_createProgram(params) {
return request({
  url: envInfo.bgApp.securityPath + '/tenant/program/create',
  method: 'post',
  data: params
})
}

//更改数据权限
export function _tenant_updateProgram(params) {
return request({
  url: envInfo.bgApp.securityPath + '/tenant/program/update', 
  method: 'put',
  data: params
})
}

//删除数据权限
export function _tenant_deleteProgram(params) {
return request({
  url: envInfo.bgApp.securityPath + '/tenant/program/deleteProgram',
  method: 'delete',
  data: params
})
}







